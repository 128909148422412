.form-wrapper {
    padding: 2rem;
    background-color: #d3cdc6;
    width: 560px;
    margin-top: 40px;
    margin-bottom: 2rem;
}

.form-wrapper form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 15px;
}

.form-wrapper .form-field {
    display: flex;
    flex-direction: column;
}

.form-field label {
    font-weight: 500;
}