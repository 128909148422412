.about-wrapper {
    padding-left: 14rem;
    padding-right: 14rem;
    padding-top: 6rem;
    padding-bottom: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f3ede5;
    gap: 2rem;
}

.about-wrapper .left {
    width: 80%;
}

.about-wrapper .left .badge {
    margin-bottom: 20px;
    font-size: 18px;
}

.about-wrapper .left .heading {
    font-weight: 400;
    font-size: 60px;
}

.about-wrapper .description {
    margin-top: 30px;
    line-height: 1.6;
    font-size: 18px;
    width: 80%;
}

.about-wrapper .left button {
    margin-top: 30px;
}

.about-wrapper .right {
    height: 100%;
}

.about-wrapper .img-container {
    width: 100%;
    position: relative;
    z-index: 1;
}

.about-wrapper .right img {
    width: 100%;
    height: 100%;
}

.about-wrapper .right .img-container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: -20px;
    left: 20px;
    background-color: #edb162;
    z-index: -1;
}

@media (max-width: 1350px) {
    .about-wrapper .left .heading {
        font-weight: 400;
        font-size: 40px;
    }

    .about-wrapper .description {
        font-size: 16px;
    }

    .about-wrapper .img-container {
        width: 80%;
    }
}

@media (max-width: 1200px) {
    .about-wrapper {
        padding-left: 12rem;
        padding-right: 12rem;
        gap: 4rem;
    }

    .about-wrapper .img-container {
        margin-left: 4rem;
    }
}

@media (max-width: 1000px) {
    .about-wrapper .description {
        font-size: 14px;
        width: 140%;
    }

    .about-wrapper {
        flex-direction: column;
    }

    .about-wrapper .img-container {
        width: 60%;
    }

    .about-wrapper button {
        font-size: 12px;
    }
}

@media (max-width: 700px) {
    .about-wrapper .img-container {
        width: 70%;
    }

    .about-wrapper .left .heading {
        font-weight: 400;
        font-size: 30px;
    }

    .about-wrapper {
        padding-left: 10rem;
        padding-right: 10rem;
    }

    .about-wrapper .img-container {
        width: 130%;
        margin-left: 0;
    }

    .about-wrapper .right img {
        height: 100%;
    }
}

@media (max-width: 500px) {
    .about-wrapper .description {
        font-size: 14px;
        width: 140%;
    }

    .about-wrapper {
        padding-left: 6rem;
        padding-right: 6rem;
    }

    .about-wrapper button {
        font-size: 12px;
    }
}