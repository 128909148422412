.blog-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 8rem;
    padding-bottom: 4rem;
    background-color: #f3ede5;
}

.blog-wrapper .badge {
    font-size: 18px;
    margin-bottom: 8px;
}

.blog-wrapper .heading {
    font-weight: 400;
    font-size: 56px;
    margin-bottom: 20px;
}

.blog-posts {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.blog-posts .post {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.post .banner {
    width: 100%;
}

.post .banner img {
    width: 100%;
}

.post .title {
    font-size: 24px;
}

.post .tag {
    font-weight: 500;
    margin-top: 10px;
}