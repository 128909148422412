.service-section {
    padding-left: 14rem;
    padding-right: 14rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
}

.section-img {
    width: 100%;
    height: 400px;
}

.section-img img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}