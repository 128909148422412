.video-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 8rem;
    padding-bottom: 4rem;
    background-color: #f3ede5;
}

.video-wrapper .badge {
    font-size: 18px;
    margin-bottom: 8px;
}

.video-wrapper .heading {
    font-weight: 400;
    font-size: 56px;
    margin-bottom: 20px;
}

.videos-posts {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}

.videos-posts .vid {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.vid .banner {
    width: 100%;
}

.vid .banner img {
    width: 100%;
}

.vid .title {
    font-size: 24px;
}

.vid .tag {
    font-weight: 500;
    margin-top: 10px;
}

@media (max-width: 1050px) {
    .vid .title {
        font-size: 16px;
    }

    .vid .blurb {
        font-size: 12px;
    }
}

@media (max-width: 955px) {
    .videos-posts {
        flex-direction: column;
        align-items: center;
    }

    .videos-posts .vid {
        width: 80%;
    }

    .vid .title {
        font-size: 24px;
    }

    .vid .blurb {
        font-size: 16px;
    }
}

@media (max-width: 650px) {
    .video-wrapper {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .video-wrapper .heading {
        font-weight: 400;
        font-size: 30px;
        margin-bottom: 20px;
    }

    .vid .title {
        font-size: 18px;
    }
}

@media (max-width: 460px) {
    .videos-posts {
        gap: 40px;
    }

    .vid .blurb {
        font-size: 12px;
    }
}