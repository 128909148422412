@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

*::selection {
  background-color: #1E1E1E;
  color: #F0EADE;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  background-color: #f1e9d9;
  color: #262a37;
  max-width: 100%;
  overflow-x: hidden;
}

.font-title {
  font-family: "Playfair Display", serif;
}

button {
  padding: 16px 22px;
  font-size: 16px;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

button.primary {
  background-color: #edb162;
  color: #262a37;
}

button.ghost {
  background-color: transparent;
  color: #262a37;
  border: 2px solid #272c3b;
}

button.ghost:hover {
  background-color: #272c3b;
  color: #f3ede5;
}

.color-primary {
  color: #edb162;
}

.color-mute {
  color: rgb(121, 119, 119);
}

input {
  outline: none;
  padding: 12px 8px;
  border: 1px solid gray;
}

input:focus {}