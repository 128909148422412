.blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.highlight-container {
    width: 100%;
}

.highlight-header {
    margin-top: 8px;
    margin-bottom: 20px;
}

.highlight-header h3 {
    font-size: 32px;
}

.highlights-row {
    display: flex;
    gap: 1rem;
    width: 100%;
}

.single-highlight {
    width: 600px;
}

.single-highlight .highlight-thumbnail {
    width: 100%;
}

.highlight-thumbnail img {
    width: 600px;
}

.highlight-tag {
    margin-top: 14px;
    text-transform: uppercase;
    font-weight: 500;
}

.highlight-title {
    font-size: 22px;
}