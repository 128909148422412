nav {
    min-height: 12vh;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 6rem;
    padding-right: 6rem;
}

.logo {
    font-size: 22px;
    font-weight: 600;
    cursor: pointer;
}

nav .right {
    width: 56%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 64%;
}

.links a {
    color: black;
    text-decoration: none;
}

.hamburger {
    display: none;
}

@media (max-width: 1350px) {
    nav {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .logo {
        font-size: 20px;
    }
}

@media (max-width: 1150px) {
    nav .right {
        width: 70%;
    }

    .links {
        width: 80%;
    }
}

@media (max-width: 1000px) {
    nav .right {
        display: none;
    }

    nav .hamburger {
        display: flex;
        flex-direction: column;
        gap: 5px;
        cursor: pointer;
    }

    .hamburger .line1,
    .hamburger .line2,
    .hamburger .line3 {
        width: 30px;
        height: 3px;
        background-color: black;
    }
}

@media (max-width: 650px) {
    .logo {
        font-size: 18px;
        width: 90%;
    }
}