.why-us-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 14rem;
    padding-right: 14rem;
    padding-top: 8rem;
    padding-bottom: 4rem;
    background-color: #072C27;
    gap: 1rem;
    color: #e9e9e8;
}

.why-us-wrapper p::selection,
.why-us-wrapper h1::selection,
.why-us-wrapper h2::selection {
    color: #072C27;
    background-color: #e9e9e8;
}

.why-us-wrapper .heading {
    font-weight: 400;
    font-size: 58px;
}

.why-us-wrapper .info-wrapper {
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.info-wrapper .left {
    width: 50%;
    padding: 0 4rem;
    border-right: 1px solid rgba(243, 237, 229, 0.1);
    position: relative;
}

.info-wrapper .left .percentage {
    font-size: 70px;
}

.info-wrapper .left .description {
    font-size: 20px;
    width: 75%;
    margin-top: 0.5rem;
}

.info-wrapper .left button {
    position: absolute;
    bottom: 0;
}

.info-wrapper .right {
    width: 50%;
    padding-left: 8rem;
    padding-right: 4rem;
}

.info-wrapper .right {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 2.5rem;
}

.right .info-box {
    width: 100%;
}

.right .info-box .number {
    font-size: 52px;
}

.right .info-box .info-description {
    width: 80%;
}

.right .line {
    width: 70%;
    border: none;
    height: 1px;
    background-color: rgba(243, 237, 229, 0.1);
}

@media (max-width: 1250px) {
    .why-us-wrapper .info-wrapper {
        flex-direction: column;
    }

    .info-wrapper .left {
        width: 100%;
        padding: 0 4rem;
        border-right: none;
    }

    .info-wrapper .left .description {
        width: 100%;
    }

    .info-wrapper .left button {
        position: relative;
        top: 20px;
        margin-bottom: 50px;
    }

    .info-wrapper .right {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 2.5rem;
        width: 100%;
    }
}

@media (max-width: 1050px) {
    .why-us-wrapper .heading {
        font-size: 44px;
    }
}

@media (max-width: 900px) {
    .why-us-wrapper {
        padding-left: 10rem;
        padding-right: 10rem;
        padding-top: 6rem;
    }

    .why-us-wrapper .heading {
        font-size: 44px;
    }
}