.services-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 6rem;
    padding-right: 6rem;
    padding-top: 8rem;
    padding-bottom: 4rem;
    background-color: #f3ede5;
}

.services-wrapper .badge {
    font-size: 18px;
    margin-bottom: 10px;
}

.services-wrapper .heading {
    font-weight: 400;
    font-size: 56px;
    margin-bottom: 20px;
}

.services-wrapper .description {
    text-align: center;
    width: 70%;
}

.services-wrapper .services-grid {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    border: 1px solid rgba(121, 119, 119, 0.3);
    border-right: none;
    border-bottom: none;
    width: 100%;
    margin-top: 4rem;
}

.services-grid .service {
    border-right: 1px solid rgba(121, 119, 119, 0.3);
    border-bottom: 1px solid rgba(121, 119, 119, 0.3);
    padding: 2rem;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.services-grid .service:hover {
    background-color: #1d1f26;
    color: #f1e9d9;
    cursor: pointer;
}

.service .bottom {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.service .bottom .title {
    font-size: 24px;
}

@media (max-width: 1050px) {
    .services-wrapper .services-grid {
        display: grid;
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(2, 1fr);
        border: 1px solid rgba(121, 119, 119, 0.3);
        border-right: none;
        border-bottom: none;
        width: 100%;
        margin-top: 4rem;
    }
}

@media (max-width: 650px) {
    .services-wrapper .heading {
        font-weight: 400;
        font-size: 30px;
        margin-bottom: 20px;
    }

    .services-wrapper .description {
        text-align: center;
        width: 120%;
    }

    .services-grid .service {
        border-right: 1px solid rgba(121, 119, 119, 0.3);
        border-bottom: 1px solid rgba(121, 119, 119, 0.3);
        padding: 1rem;
        height: 250px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .service .bottom .title {
        font-size: 18px;
    }
}

@media (max-width: 550px) {
    .services-grid .service {
        border-right: 1px solid rgba(121, 119, 119, 0.3);
        border-bottom: 1px solid rgba(121, 119, 119, 0.3);
        padding: 1rem;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .service .bottom .title {
        font-size: 16px;
    }
}